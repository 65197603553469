import { apiMyRecordword } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            resultData: [],
            page: 1,
            loading: false,
            noMore: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getMyRecordword()
        },
        getMyRecordword() {
            // this.loading = true
            let paramsBase = {
                page: this.page,
            }
            apiMyRecordword(paramsBase).then((res) => {
                let dataValue = res.data.list
                if (dataValue.length != 0) {
                    setTimeout(() => {
                        dataValue.forEach(element => {
                            element.show = false
                            this.resultData.push(element)
                        });
                    }, 500);

                } else {
                    this.loading = false
                    this.noMore = true
                }
            })
        },
        load() {
            this.page++
            this.getMyRecordword()
        },
        clickVolume(index) {
            let audios = document.getElementsByTagName('audio');
            [...audios].forEach((item) => {
                item.load()
            });
            this.$refs.audio[index].play()
        }
    },
}